@import "../../styles/mixins/index.scss";

.home {
  position: relative;
  background-color: var(--begie);
  min-height: 100vh;
  padding-block-end: 30px;
  background-image: url("../../assets/images/intro/intro1.png"),
    url("../../assets/images/intro/intro2.png"),
    url("../../assets/images/intro/intro3.png"),
    url("../../assets/images/intro/intro4.png");
  background-position: bottom left, bottom left 18%, bottom right 18%,
    bottom right;
  background-size: auto, 430px, 430px, auto;
  background-repeat: no-repeat;
  @include lg {
    background-image: url("../../assets/images/intro/intro1.png");
    background-position: bottom left;
  }
  &__title {
    max-width: 700px;
    text-align: center;
    margin: 20px auto 80px auto;
  }
}

.meet {
  text-align: center;
  &__btn {
    display: inline-block;
    background-color: var(--red);
    border: 0.5px solid var(--gray);
    text-transform: uppercase;
    color: var(--white);
    padding: 18px 54px;
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 20px;
  }
}
