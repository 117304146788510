@import "../../styles/mixins/index.scss";

.team {
  position: relative;
  background-color: var(--begie);
  min-height: 100vh;
  &__wrap {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
}

.block {
  width: calc(100% / 3 - 20px);
  @include md {
    width: calc(100% / 2 - 20px);
  }
  img {
    margin-bottom: 30px;
    @include md {
      margin-bottom: 12px;
    }
  }
  @include xs {
    width: 100%;
  }
  a {
    color: var(--gray);
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    gap: 10px;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white-sec);
    border-radius: 6px;
    padding: 12px 10px;
  }
  &__position {
    font-size: 18px;
    font-weight: 700;
    color: var(--green);
    text-transform: uppercase;
    @include sm {
      font-size: 14px;
    }
  }
}
