@import "../../styles/mixins/index.scss";

.faq {
  position: relative;
  background-color: var(--begie);
  min-height: 100vh;
  background-image: url("../../assets/images/team/teamBg1.png"),
    url("../../assets/images/team/teamBg2.png");
  background-position: left bottom 200px, right bottom;
  background-repeat: no-repeat;
  @include lg {
    background-image: url("../../assets/images/team/teamBg2.png");
    background-position: right bottom;
  }
  &__wrap {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px 32px;
    margin-bottom: 46px;
    @include md {
      grid-template-columns: auto;
    }
  }
}

.join {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  @include md {
    flex-direction: column;
  }
  &__img {
    max-width: 684px;
    width: 100%;
  }
  &__btns {
    flex-wrap: wrap;
    display: flex;
    gap: 32px;
  }
  &__info {
  }
}
