@import '../../styles/mixins/index.scss';

.story {
    position: relative;
    background-color: var(--begie);
    min-height: 100vh;
    &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 32px;
        @include md {
            flex-direction: column;
        }
    }
    &__img {
        max-width: 500px;
        width: 100%;
        flex: 0 0 30%;
    }
}