@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;700;900&display=swap");
@import "../mixins/index.scss";

:root {
  --black: #000000;
  --dark: #1a1f26;
  --white: #ffffff;
  --white-sec: #fcf6f4;
  --white-third: #fcfbf4;
  --green: #518700;
  --begie: #ededed;
  --gray: #202d3b;
  --red: #d54700;
  --orange: #fa6c3d;
  --violet: #6461e2;
  --menu-bg-fixed: var(--begie);
  --menu-bg-mobile: var(--begie);
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  color: var(--black);
  font-family: "Outfit", Arial;
}

h2 {
  font-size: 80px;
  color: var(--red);
  margin-bottom: 32px;
  font-weight: 900;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px var(--gray);
  @include sm {
    font-size: 40px;
  }
}

h3 {
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  @include lg {
    font-size: 24px;
    line-height: 26px;
  }
  @include sm {
    line-height: 20px;
    font-size: 18px;
  }
}

h4 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 22px;
  color: var(--green);
  margin-bottom: 8px;
}

p {
  margin-bottom: 20px;
}

.container {
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 1320px;
  padding-inline-start: 24px;
  padding-inline-end: 24px;
  @include xl {
    max-width: initial;
    width: 100%;
  }
}

.btn {
  display: inline-flex;
  padding: 18px 46px;
  gap: 10px;
  color: var(--dark);
  align-items: center;
  background-color: var(--white-third);
  border: 1px solid var(--gray);
  border-radius: 6px;
  height: 60px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}
