@import "../../styles/mixins/index.scss";

.collapse {
  justify-content: flex-start;
  position: relative;
  color: var(--dark);
  transition: 2s background-color;
  cursor: pointer;
  background-color: var(--white-third);
  margin-bottom: 10px;
  padding: 32px;
  border-radius: 6px;
  &_active {
    background: var(--green);
    color: var(--white);
    .collapse--icon {
      transform: rotate(90deg);
      color: var(--white);
      margin-top: -20px;
    }
  }
  &--title {
    margin-top: 0;
    display: flex;
    align-items: center;
    gap: 40px;
    font-size: 18px;
    text-transform: uppercase;
    @include md {
      font-size: 20px;
    }
    &_wrap {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  &--id {
    font-size: 36px;
  }
  &--desc {
    margin-bottom: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s, margin 0.3s;
    padding-left: 42px;
    &_active {
      overflow: initial;
      opacity: 1;
      max-height: 1200px;
      margin-top: 20px;
    }
    & > ul {
      list-style-type: disc;
      padding: 24px 28px 24px 48px;
      li {
        list-style-type: initial;
        margin-bottom: 20px;
      }
    }
    & > p {
      margin-bottom: 10px;
      a {
        color: var(--white);
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  &--icon {
    position: relative;
    width: 0;
    transition: transform 0.3s;
    color: var(--green);
    font-size: 22px;
  }
}
