@import "../../styles/mixins/index.scss";

.leekmap {
  background-image: url("../../assets/images/leekmap/bg.png");
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 40px;
    @include sm {
      gap: 10px;
    }
  }
  &__btn {
    position: relative;
    width: calc(100% / 4 - 24px);
    background-color: var(--begie);
    padding: 20px 30px;
    cursor: pointer;
    text-align: left;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    @include lg {
      width: calc(100% / 2 - 15px);
    }
    @include sm {
      text-align: center;
      width: calc(100% / 2 - 5px);
      padding: 5px;
    }
    img {
      width: 100%;
    }
    &:before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 25px 25px 25px;
      border-color: transparent transparent var(--begie) transparent;
      opacity: 0;
      transform: translate(-50%, 0);
      transition: 0.3s transform, .3s opacity;
      @include xl {
        display: none;
      }
    }
    &_active {
        border: solid 1px var(--gray);
      &:before {
        opacity: 1;
        transform: translate(-50%, 42px);
      }
    }
  }
  &__id {
    font-size: 120px;
    color: var(--white-sec);
    font-weight: 900;
    line-height: 1;
    margin-bottom: 10px;
    @include md {
      font-size: 60px;
      margin: 0 auto 10px auto;
    }
  }
  &__title {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    color: var(--black);
    @include sm {
      writing-mode: initial;
      transform: rotate(0deg);
      margin: 0 auto 10px;
    }
  }
  &__info {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @include sm {
      flex-direction: column;
    }
  }
  &__description {
    margin: 20px 0 40px;
    background-color: var(--begie);
    padding: 70px 110px;
    border-radius: 5px;
    @include md {
      padding: 10px;
    }
  }
}
