@import "../../styles/mixins/index.scss";

.stake {
  position: relative;
  background-color: var(--begie);
  min-height: 100vh;
  background-image: url("../../assets/images/staking/bg.png");
  background-repeat: no-repeat;
  background-position: right;
}

.footer {
  margin-top: 54vh;
}
